<!--todo 非单向数据流-->
<template>
  <div>
    <!-- <div  :class="['nameWrap','editName',value.row.level]" v-if="editNameShow">
            <el-row style="width:100%" :gutter="10" type="flex" align="middle">
                <el-col :span="18">
                    <el-input v-model="value.row.name"></el-input>
                </el-col>
                <el-col :span="3">
                    <el-button size="mini" type="text"  @click.stop="editName(value.row.name,value.row.id)">确定</el-button>
                </el-col>
                <el-col :span="3">
                    <el-button size="mini" type="text"  @click.stop="value.row.name=oldName;editNameShow = false">取消</el-button>
                </el-col>
            </el-row>
        </div> -->
    <div
      :class="[
        'nameWrap',
        value.row.level,
        level == value.row.level ? 'fixWidth' : '',
      ]"
      v-if="!editNameShow"
    >
      <p
        v-if="value.row.level != 'ad'"
        :class="['name', level]"
        @click.stop="openChildren(value)"
      >
        <el-tooltip
          :content="value.row.name"
          placement="top-start"
          effect="dark"
        >
          <el-link class="name-link ellipsis-1" type="primary">{{
            value.row.name
          }}</el-link>
        </el-tooltip>
      </p>

      <p v-else :class="['name', level]" @click.stop="openChildren(value)">
        <el-image
          class="ad-image"
          :src="value.row.creative_thumbnail"
        ></el-image>
        <span>{{ value.row.name }}</span>
      </p>

      <!-- <div :class="['tools',`tools${value.row.id}`]" v-if="!value.row.breakdown_object_id">
                <i class="el-icon-edit" @click.stop="editNameShow = true;oldName = value.row.name" style="margin:0 5px;cursor:pointer"></i>               
            </div> -->

      <div
        :class="['tools-new-line', 'tools', `toolsNew${value.row.id}`]"
        v-if="!value.row.breakdown_object_id"
      >
        <!-- <span @click.stop v-if="value.row.effective_status !='ARCHIVED' && value.row.effective_status !='DELETED'">
                    <el-dropdown trigger="click" @command="handleEditCommand" placement="bottom" ref="editmessageDrop">
                        <el-link type="info">编辑</el-link>
                        <el-dropdown-menu slot="dropdown" style="width:200px" @mouseover.native="showTools(value.row.id,'editmessageDrop')" @mouseout.native="hideTools(value.row.id,'editmessageDrop')">
                            <span v-if="value.row.level == 'campaign'">
                                <el-dropdown-item disabled style="font-size: 12px;">
                                    <div class="columnName">
                                        <span>广告系列</span>
                                        <div>
                                            已选1项
                                        </div>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'campaign',scope:value}">修改预算与竞价</el-dropdown-item>
                            </span>
                            <span v-if="value.row.level == 'adset'">
                                <el-dropdown-item disabled style="font-size: 12px;">
                                    <div class="columnName">
                                        <span>广告组</span>
                                        <div>
                                            已选1项
                                        </div>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'adset',scope:value}">修改预算与竞价</el-dropdown-item>
                                <el-dropdown-item :command="{type:'editAdset',scope:value}">编辑广告组</el-dropdown-item>
                            </span>
                            <span v-if="value.row.level == 'ad'">
                                <el-dropdown-item disabled style="font-size: 12px;">
                                    <div class="columnName">
                                        <span>广告</span>
                                        <div>
                                            已选1项
                                        </div>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'editAd',scope:value}">编辑广告</el-dropdown-item>
                            </span>
                        </el-dropdown-menu>
                    </el-dropdown>     
                </span> -->
        <!-- <span v-if="value.row.effective_status !='ARCHIVED' && value.row.effective_status !='DELETED'">
                    <el-link type="info" @click.stop="chartDrawersShow(value.row)">查看图表</el-link>
                </span> -->
        <!-- <span @click.stop v-if="value.row.effective_status !='ARCHIVED' && value.row.effective_status !='DELETED'">
                    <el-dropdown trigger="click" @command="handleMoreCommand" placement="bottom" ref="messageDrop">
                        <el-link type="info">更多</el-link>
                        <el-dropdown-menu slot="dropdown" style="width:120px" @mouseover.native="showTools(value.row.id,'messageDrop')" @mouseout.native="hideTools(value.row.id,'messageDrop')">
                            <el-dropdown-item :command="{type:'copy',scope:value}">复制</el-dropdown-item>
                            <el-dropdown-item :command="{type:'creatAdset',scope:value}"  v-if="value.row.level == 'campaign'">创建广告组</el-dropdown-item>
                            <el-dropdown-item :command="{type:'creatAd',scope:value}"  v-if="value.row.level == 'adset'">创建广告</el-dropdown-item>
                            <el-dropdown-item :command="{type:'delete',level:value.row.level,id:value.row.level,scope:value}">删除</el-dropdown-item>
                            <el-dropdown-item :command="{type:'preview',level:value.row.level,id:value.row.level,scope:value}" v-if="value.row.level == 'ad'">预览</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span> -->
      </div>

      <!-- <span
        v-if="
          !value.row.breakdown_object_id &&
          value.row.effective_status != 'ARCHIVED' &&
          value.row.effective_status != 'DELETED'
        "
        class="switch"
      >
        <el-switch
          @click.stop.native
          v-model="value.row.status"
          active-value="ACTIVE"
          inactive-value="PAUSED"
          size="mini"
          @change="$emit('setStatus', value)"
          v-if="value.row.status == 'ACTIVE' || value.row.status == 'PAUSED'"
        ></el-switch>
        <el-switch v-model="disabled" size="mini" disabled v-else></el-switch>
      </span> -->
    </div>
    <preview-ad
      @close="close"
      :previewAds="previewAds"
      :value="value.row"
    ></preview-ad>
    <edit-buyget-type v-if="platform==='facebook'"
      :editBuygetTypeShow="editBuygetTypeShow"
      @close="close"
      :row="value.row"
      :date-range="dateRange"
    ></edit-buyget-type>
  </div>
</template>

<script>
import { mutateStatus, mutateName } from "@/api/adManagement";
import previewAd from "./previewAds";
import editBuygetType from "./editBuygetType";
//图表
export default {
  name: "AdCellName",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    level: {
      type: String,
      default: "campaign",
    },
    dateRange: {
      type: Object,
      default: () => {},
    },
    platform: {
      type: String,
      default: "facebook",
    },
  },
  data() {
    return {
      editNameShow: false,
      oldName: "",
      drawers: {},
      previewAds: false,
      editBuygetTypeShow: false,
    };
  },
  components: {
    previewAd,
    editBuygetType,
  },
  methods: {
    close(v) {
      this[v] = false;
    },
    // 点击跳转下级
    openChildren(scope) {
      let newLevel = scope.row.level == "campaign" ? "adset" : "ad";
      // console.log(newLevel);
      this.$store.commit("batchAdActiveTab/setActiveTab", newLevel);
      this.$emit("open-next-level", scope.row, newLevel);
    },
    // 点击打开图表
    chartDrawersShow(row) {
      this.$emit("openChart", row);
      // this.drawersShow('chart');
    },
    drawersShow(drawerName) {
      let scope = this.value;
      this.$emit("drawers-show", drawerName, [scope]);
    },
    refreshTableRow() {
      let scope = this.value;
      this.$emit("scope-row-changed", scope);
    },
    cancelEdit() {
      let scope = this.value;
      let result = parseInt(scope.row.budget_editing) * 100;
      if (
        result === parseInt(scope.row.daily_budget) ||
        result === parseInt(scope.row.lifetime_budget)
      ) {
        scope.row.budget_editing = undefined;
        this.refreshTableRow();
      }
    },
    formatedMoney(v) {
      return "$" + (parseFloat(v) / 100).toFixed(2);
    },
    beforeEdit(isDaily) {
      let scope = this.value;
      if (isDaily) {
        scope.row.budget_editing = parseFloat(scope.row.daily_budget) / 100;
      } else {
        scope.row.budget_editing = parseFloat(scope.row.lifetime_budget) / 100;
      }
      this.refreshTableRow();
    },
    // 编辑名称
    editName(name, id) {
      // this.editNameShow = true;
      let params = {
        name: name,
        id: id,
      };
      mutateName(JSON.stringify(params),this.value.row.rowThirdNum).then((res) => {
        if (res.code == 0) {
          this.refreshTableRow();
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.editNameShow = false;
        } else {
          this.$message({
            type: "error",
            message: "修改失败",
          });
          scope.row.name = this.oldName;
          this.refreshTableRow();
        }
      });
    },
    // 点击编辑
    handleEditCommand(v) {
      // console.log(v);
      // 编辑广告系列竞价预算
      if (v.type == "campaign" || v.type == "adset") {
        this.$emit("handle-edit-command", v);
      }
      // 编辑广告组
      if (v.type == "editAdset") {
        this.$emit("handle-edit-command", v);
      }
      // 编辑广告
      if (v.type == "editAd") {
        this.$emit("handle-edit-command", v);
      }
      // 转换预算类型
      if (v.type == "editBuygetType") {
        this.editBuygetTypeShow = true;
      }
    },
    // 点击更多
    handleMoreCommand(v) {
      // 复制操作
      if (v.type == "copy") {
        this.$emit("copy", v.scope);
      }
      // 删除操作
      if (v.type == "delete") {
        this.$emit("setStatus", v.scope, "DELETED");
      }
      // 预览
      if (v.type == "preview") {
        this.previewAds = true;
      }
      // 创建广告组
      if (v.type == "creatAdset") {
        this.$router.push({
          name: "CreateAd",
          query: {
            creatAdset: true,
            campaignId: v.scope.row.id,
            campaignName: v.scope.row.name,
          },
        });
        // this.previewAds = true;
      }
      // 创建广告
      if (v.type == "creatAd") {
        this.$emit("handle-edit-command", v);
      }
    },
    // 鼠标放在dropdown时。工具栏显示
    showTools(id, ref) {
      let tools = document.querySelector(`.tools${id}`);
      let toolsNew = document.querySelector(`.toolsNew${id}`);
      tools.classList.add("toolsShow");
      toolsNew.classList.add("toolsNewShow");
      this.$refs[ref].show();
    },
    // 隐藏工具栏
    hideTools(id, ref) {
      let tools = document.querySelector(`.tools${id}`);
      let toolsNew = document.querySelector(`.toolsNew${id}`);
      tools.classList.remove("toolsShow");
      toolsNew.classList.remove("toolsNewShow");
      this.$refs[ref].hide();
    },
  },
  mounted() {},
  directives: {
    "cell-focus": {
      bind(el, binding) {
        // el.focus();
        setTimeout(() => {
          el.children[0].focus();
        }, 200);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.nameWrap {
  width: 91%;
  padding: 5px 0;
  &.campaign {
    width: 91%;
  }

  &.adset {
    width: 87%;
  }

  &.ad {
    width: 83%;
  }
  &.fixWidth {
    width: 91% !important;
  }

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  .editName {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: -8px;
    height: auto;
    // background: #fff;
    z-index: 66;
  }
  .switch {
    position: absolute;
    right: 0;
  }

  .name {
    width: 75%;
    .name-link {
      display: inline-block;
      width: 100%;
    }
    display: flex;
    align-items: center;
    white-space: nowrap;
    &.campaign,
    &.adset {
      cursor: pointer;
    }
    .el-image {
      width: 38px;
      height: 38px;
      margin-right: 6px;
    }
    position: absolute;
    top: 14%;
    &.ad {
      top: 14%;
    }

    .ad-image {
      position: absolute;
      top: 3px;
      left: 0;
    }
  }

  .ad {
    padding-left: 48px;
  }

  &:hover {
    .tools {
      display: block;
      position: absolute;
      right: 0;
      top: 14%;
      padding-right: 60px;
    }
    .tools-new-line {
      top: 56%;
      left: 0;
    }
    &.ad {
      .tools {
        top: 14%;
      }
      .tools-new-line {
        top: 56%;
        left: 48px;
      }
    }
  }
  .toolsShow {
    display: block !important;
    position: absolute;
    right: 0;
    top: 14%;
    padding-right: 60px;
  }
  .toolsNewShow {
    display: block !important;
    position: absolute;
    right: 0;
    top: 56%;
    left: 0;
  }
  &.ad {
    .toolsShow {
      top: 14%;
    }
    .toolsNewShow {
      top: 56%;
      left: 48px;
    }
  }
  .tools {
    display: none;
    position: absolute;
    right: 0;
    top: 14%;
    padding-right: 60px;
    span {
      margin-right: 10px;
    }
  }
  .tools-new-line {
    position: absolute;
    right: 0;
    top: 56%;
    left: 0;
  }
  &.ad {
    .tools {
      top: 14%;
    }
    .tools-new-line {
      top: 56%;
      left: 48px;
    }
  }
}
</style>