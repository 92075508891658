<!--todo 非单向数据流-->
<template>
  <div v-if="!value.row.breakdown_object_id" style="width: 200px">
    <div v-if="value.row.daily_budget">
      <span v-if="value.row.budget_editing === undefined"
        >{{ formatedMoney(value.row.daily_budget) }}
        <i
          style="display: none"
          class="el-icon-edit-outline"
          @click="beforeEditBuget(true)"
          v-if="
            value.row.effective_status != 'ARCHIVED' &&
            value.row.effective_status != 'DELETED'
          "
        ></i
      ></span>
      <span style="display: none" v-if="value.row.budget_editing !== undefined">
        <el-input
          size="small"
          v-model="value.row.budget_editing"
          @input="refreshTableRow"
          v-cell-focus
          @blur="cancelEditBuget"
          @change="editBudget(true)"
        >
        </el-input>
      </span>
      <!-- <br /><span class="color9">日预算</span> -->
    </div>
    <div v-else-if="value.row.lifetime_budget">
      <span v-if="value.row.budget_editing === undefined"
        >{{ formatedMoney(value.row.lifetime_budget) }}
        <i
          style="display: none"
          class="el-icon-edit-outline"
          @click="beforeEditBuget(false)"
          v-if="
            value.row.effective_status != 'ARCHIVED' &&
            value.row.effective_status != 'DELETED'
          "
        ></i
      ></span>
      <span v-if="value.row.budget_editing !== undefined">
        <el-input
          size="small"
          v-model="value.row.budget_editing"
          @input="refreshTableRow"
          v-cell-focus
          @blur="cancelEditBuget"
          @change="editBudget(false)"
        >
        </el-input>
      </span>
      <!-- <br />总预算 -->
    </div>
    <div style="display: none" v-else-if="value.row.level === 'campaign'">
      使用广告组预算
    </div>
    <div style="display: none" v-else-if="value.row.level === 'adset'">
      使用广告系列预算
    </div>
    <div v-else>-</div>
    <div
      style="display: none"
      class="trans"
      v-if="value.row.level === 'campaign'"
    >
      <span style="color: #999; font-size: 12px">是否开启广告系列预算：</span>
      <el-popover
        placement="top-start"
        title="转换预算"
        width="500"
        trigger="hover"
        :content="!is_cbo ? textList[0] : textList[1]"
      >
        <el-switch
          v-model="is_cbo"
          size="mini"
          slot="reference"
          @change="transBudget"
        ></el-switch>
      </el-popover>
    </div>
    <edit-buyget-type
      :editBuygetTypeShow.sync="editBuygetTypeShow"
      @close="close"
      :row="value.row"
      :date-range="dateRange"
      :is_cbo.sync="is_cbo"
      :textList="textList"
      @refreshTable="refreshTable"
    ></edit-buyget-type>
  </div>
</template>

<script>
import { mutatePricing } from "@/api/adManagement";
import { mapGetters } from "vuex";
import editBuygetType from "./editBuygetType";
export default {
  name: "AdCellInlineEditBudget",
  props: {
    value: {
      type: Object,
    },
    dateRange: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editBuygetTypeShow: false,
      is_cob: false,
      textList: [
        "使用广告系列预算优化后，投放中的广告的剩余预算将合并为一项广告系列预算，广告系列预算优化会在启用15分钟后生效，你需要两小时后才能关闭这项功能",
        "关闭广告系列预算优化后，会把未花费的预算分配到各个广告组，各个广告组会使用相同的竞价策略和竞价上限(如适用)，如关闭广告系列预算优化，你需要2小时后才能再次打开",
      ],
    };
  },
  components: {
    editBuygetType,
  },
  computed: {
    ...mapGetters(["toFormatedMoney"]),
  },
  created() {
    if (this.value.row.daily_budget || this.value.row.lifetime_budget) {
      this.is_cbo = true;
    } else {
      this.is_cbo = false;
    }
    this.$forceUpdate();
  },
  methods: {
    close(v) {
      this.is_cbo = !this.is_cbo;
      this[v] = false;
      console.log(this.is_cbo);
      this.$forceUpdate();
    },
    refreshTableRow() {
      let scope = this.value;
      this.$emit("scope-row-changed", scope);
    },
    refreshTable() {
      this.$emit("get-list");
    },
    cancelEditBuget() {
      let scope = this.value;
      let result = this.$store.getters.toBaseMoney(scope.row.budget_editing);
      if (
        result === parseInt(scope.row.daily_budget) ||
        result === parseInt(scope.row.lifetime_budget)
      ) {
        scope.row.budget_editing = undefined;
        this.refreshTableRow();
      }
    },
    formatedMoney(v) {
      return this.toFormatedMoney(v).formated || "";
      // return "$" + (parseFloat(v) / 100).toFixed(2);
    },
    beforeEditBuget(isDaily) {
      let scope = this.value;
      if (isDaily) {
        scope.row.budget_editing = this.$store.getters.toFormatedMoney(
          scope.row.daily_budget
        ).value; //parseFloat(scope.row.daily_budget) / 100;
      } else {
        scope.row.budget_editing = this.$store.getters.toFormatedMoney(
          scope.row.lifetime_budget
        ).value; //parseFloat(scope.row.lifetime_budget) / 100;
      }
      this.refreshTableRow();
    },
    editBudget(isDaily) {
      let scope = this.value;
      let row = scope.row;
      let targetVal = this.$store.getters.toBaseMoney(scope.row.budget_editing);
      let params = {
        objects: [
          {
            id: row.id,
            name: row.name,
            daily_budget: isDaily ? targetVal : null,
            lifetime_budget: isDaily ? null : targetVal,
            before_lifetime_budget:row.lifetime_budget,
            before_daily_budget:row.daily_budget
          },
        ],
        level:row.level,
      };
      mutatePricing(JSON.stringify(params)).then((res) => {
        // console.log(res)
        row.budget_editing = undefined;
        if (res.code == 0) {
          if (isDaily) {
            row.daily_budget = targetVal;
          } else {
            row.lifetime_budget = targetVal;
          }
        } else {
          this.$message({
            type: "warning",
            message: "失败了",
          });
        }
        this.refreshTableRow();
      });
    },
    transBudget(v) {
      console.log(v);
      this.is_cbo = v;
      this.editBuygetTypeShow = true;
      this.$forceUpdate();
    },
  },
  directives: {
    "cell-focus": {
      bind(el, binding) {
        // el.focus();
        setTimeout(() => {
          el.children[0].focus();
        }, 200);
      },
    },
  },
};
</script>

<style scoped>
.el-input {
  width: 80%;
}
</style>